<template>
  <div>
    <div class="orders">
      <ul class="orders-cont" v-for="(item, index) in dataList" :key="index">
        <li class="orders-item">
          <div class="orders-item-name">
            <img v-if="item.productName==='QQ会员月卡'" class="orders-item-name-icon" src="../assets/image/qq_vip.png"
                 alt="">
            <img v-if="item.productName==='腾讯视频VIP会员月卡'" class="orders-item-name-icon" src="../assets/image/tengxun.png"
                 alt="">
            <img v-if="item.productName==='优酷黄金VIP会员月卡'" class="orders-item-name-icon" src="../assets/image/youku.png"
                 alt="">
            <img v-if="item.productName==='喜马拉雅巅峰会员月卡'" class="orders-item-name-icon" src="../assets/image/ximalaya.png"
                 alt="">
            <img v-if="item.productName==='网易云黑胶VIP会员月卡'" class="orders-item-name-icon" src="../assets/image/wangyiyun.png"
                 alt="">
            <img v-if="item.productName==='芒果TV会员月卡'" class="orders-item-name-icon" src="../assets/image/mangguo.png"
                 alt="">
            <img v-if="item.productName==='酷狗音乐豪华会员月卡'" class="orders-item-name-icon" src="../assets/image/kugou.png"
                 alt="">
            <img v-if="item.productName==='QQ音乐豪华会员月卡'" class="orders-item-name-icon" src="../assets/image/qq_music.png"
                 alt="">
            <img v-if="item.productName==='爱奇艺视频会员月卡'" class="orders-item-name-icon" src="../assets/image/aiqiyi.png"
                 alt="">
            <img v-if="item.productName==='黄钻会员月卡'" class="orders-item-name-icon" src="../assets/image/huangzuan.png"
                 alt="">
            <img v-if="item.productName==='QQ音乐包'" class="orders-item-name-icon" src="../assets/image/yinyuebao.png"
                 alt="">
            <img v-if="item.productName==='美团外卖红包10元'" class="orders-item-name-icon" src="../assets/image/meituan.png"
                 alt="">
            <img v-if="item.productName==='百度文库会员月卡'" class="orders-item-name-icon" src="../assets/image/baidu_wenku.png"
                 alt="">
            <img v-if="item.productName==='百度网盘会员月卡'" class="orders-item-name-icon" src="../assets/image/baidu_wangpan.png"
                 alt="">
            <img v-if="item.productName==='南瓜电影会员月卡'" class="orders-item-name-icon" src="../assets/image/nangua.png"
                 alt="">
            <img v-if="item.productName==='酷我音乐豪华VIP月卡'" class="orders-item-name-icon" src="../assets/image/kuwo.png"
                 alt="">
            <img v-if="item.productName==='PPTV视频会员月卡'" class="orders-item-name-icon" src="../assets/image/pptv.png"
                 alt="">
            <img v-if="item.productName==='搜狐视频月卡'" class="orders-item-name-icon" src="../assets/image/souhu.png"
                 alt="">
            <img v-if="item.productName==='1905VIP会员月卡'" class="orders-item-name-icon" src="../assets/image/1905.png"
                 alt="">
            <img v-if="item.productName==='网易云音乐包月卡'" class="orders-item-name-icon" src="../assets/image/wangyiyun.png"
                 alt="">
            <img v-if="item.productName==='埋堆堆视频VIP会员月卡'" class="orders-item-name-icon" src="../assets/image/maiduidui.png"
                 alt="">
            <img v-if="item.productName==='咪咕阅读会员月卡'" class="orders-item-name-icon" src="../assets/image/migu.png"
                 alt="">
            <img v-if="item.productName==='爱奇艺体育大众会员月卡'" class="orders-item-name-icon" src="../assets/image/aiqiyi_ty.png"
                 alt="">
            <img v-if="item.productName==='知乎读书会员月卡'" class="orders-item-name-icon" src="../assets/image/zhihu.png"
                 alt="">
            <img v-if="item.productName==='陌陌会员月卡'" class="orders-item-name-icon" src="../assets/image/momo.png"
                 alt="">
            <img v-if="item.productName==='蜻蜓FM会员月卡'" class="orders-item-name-icon" src="../assets/image/qingting.png"
                 alt="">
            <span class="orders-item-name-text">{{ item.productName }}</span>
          </div>
          <div class="orders-item-msgs">
            <div class="orders-item-msgs-item" @click="showEligibilityIdTip">
              <span class="orders-item-msgs-label">所属编号:</span>
              <span class="orders-item-msgs-value" style="color:#2bc78e;">{{ item.eligibilityId }}</span>
            </div>
            <div class="orders-item-msgs-item">
              <span class="orders-item-msgs-label">充值帐号:</span>
              <span class="orders-item-msgs-value">{{ item.account }}</span>
            </div>
            <div class="orders-item-msgs-item">
              <span class="orders-item-msgs-label">兑换日期:</span>
              <span class="orders-item-msgs-value">
                {{ item.createTime }}</span>
            </div>
            <div class="orders-item-msgs-item">
              <span class="orders-item-msgs-label">当前状态:</span
              >
              <van-tag plain type="success">{{ item.statusName }}</van-tag>
            </div>
            <div class="orders-item-status open">{{ index + 1 }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {Dialog, Toast} from "vant";

export default {
  name: "Record",
  data() {
    return {
      dataList: null,
      orderForm: {
        token: null,
        batchCode: null,
        eligibilityPhone: null
      }
    };
  },
  created() {
    this.orderForm.token = this.$store.state.token;
    this.orderForm.batchCode = this.$store.state.vueConfig.vipGroupBatch;
    this.orderForm.eligibilityPhone = this.$store.state.phone;
    this.axios.post('/apiDistributeOrder/list', this.orderForm, {
      headers: {
        "Content-type": "application/json",
      },
    })
        .then((res) => {
          if (res.data.data.code !== 31000) {
            //弹出提示
            Dialog({title: '提示', message: res.data.data.message});
          } else {
            Toast(res.data.data.message);
            this.dataList = res.data.data['orderMapList'];
          }
        });
  },
  methods: {
    goBackPage() {
      this.$router.go(-1);
    },
    showEligibilityIdTip() {
      Dialog({title: '提示', message: '同一手机号可多次下单，同一编号代表一次下单的领取情况'});
    }
  }
};
</script>

<style lang="scss" scoped>
.orders {
  min-height: 100vh;
  background-color: #f3f3f3;

  .orders-cont {
    padding: 0.5rem;

    .orders-item {
      position: relative;
      padding: 0.75rem;
      margin-bottom: 0.55rem;
      border-radius: 0.5rem;
      background-color: #fff;
      box-shadow: 0 0.125rem 0.25rem 0 hsl(0deg 0% 68% / 41%);
    }

    .orders-item-name {
      font-size: 0;
      border-bottom: 1px solid #f3f3f3;
      padding-bottom: 0.3rem;

      span {
        display: inline-block;
        vertical-align: middle;
      }

      .orders-item-name-icon {
        width: 1.25rem;
        height: 1.25rem;
        vertical-align: middle;
        margin-right: 0.3rem;
        background-repeat: no-repeat;
        background-position: 0 0;
        background-size: 100% 100%;
      }

      .orders-item-name-text {
        color: #222;
        font-size: 0.75rem;
      }
    }

    .orders-item-msgs {
      .orders-item-msgs-item {
        font-size: 0;
        padding: 0.6rem 0 0.3rem 0;

        span {
          display: inline-block;
          vertical-align: middle;
        }

        .orders-item-msgs-label {
          color: #b1b1b1;
          font-size: 0.65rem;
          margin-right: 0.6rem;
        }

        .orders-item-msgs-value {
          color: #666;
          font-size: 0.75rem;
        }
      }
    }

    .orders-item-status {
      position: absolute;
      color: #fff;
      right: 0;
      top: 0;
      font-size: 0.75rem;
      padding: 0.45rem 0.75rem;
      background-color: #9fa4b0;
      border-top-right-radius: 0.45rem;
      border-bottom-left-radius: 0.45rem;

      &.open {
        background-color: #2bc78e;
      }
    }
  }
}

.pop-title {
  margin: 3rem 0 2.2rem;
  color: #222;
  font-size: 16px;
  text-align: center;
}

.pop-btn-group {
  width: 3rem;
  margin: 0 auto;

  .van-button--plain {
    margin: 0 auto;
  }
}

.van-tag {
  line-height: 1.3rem;
}
</style>
